<template>
  <header id="navigation" class="navigation">
    <div class="header-top-section">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-2 header-contact">
            <span
              ><a href="tel:1-888-968-3592"><i class="ri-phone-line"></i> 1-888-968-3592</a></span
            >
            <div class="header-socials">
              <ul>
                <li>
                  <a href="https://www.facebook.com/profile.php?id=61552521182584&mibextid=LQQJ4d" target="_blank" class=""
                    ><i class="ri-facebook-fill"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/cymcorp.international/?igsh=MWlwMTVleG84a3dkNg%3D%3D" target="_blank" class=""
                    ><i class="ri-instagram-line"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 header-info text-center">
            <p style="">
              <router-link :to="{name:'Testimonials'}" style="color:#ffffff">{{ props.translationText.text.navigationHeaderText }} </router-link>
            </p>
          </div>
          <div class="col-md-2 header-info text-center">
            <p style="color: rgb(255, 255, 255);">{{ props.translationText.text.shoppingLabel }}<br /><span>{{userLogin.getUserUrlInfoData.enrolling_sponsor_name}}, ID:{{ userLogin.getReferalId }} </span></p>
          </div>
          <div class="header-btns col-md-2">
            <ul>
              <li v-if="userLogin.checkIsLoggedIn === false">
                <router-link
                  :to="{
                    name: 'JoinTeam',
                    params: { sluguser: userLogin.getUserUrlName },
                  }"
                  class="link line-animation"
                  >{{ props.translationText.text.joinTeam }}</router-link
                >
              </li>
              <li>                
                <router-link
                  v-if="userLogin.getUserUrlName != null"
                  class="link line-animation"
                  :to="{
                    name: 'Product',
                    params: { sluguser: userLogin.getUserUrlName },
                  }"
                  >{{ props.translationText.text.shopHere }}</router-link
                >
                <router-link
                  v-else
                  class="link line-animation"
                  :to="{
                    name: 'Product',
                  }"
                  >{{ props.translationText.text.shopHere }}</router-link
                >
              </li>
              <li v-if="userLogin.checkIsLoggedIn === true">
                <button
                  class="dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="hi_text"
                    >Hi,
                    {{
                      userLogin.userData ? userLogin.userData.firstname : ""
                    }}</span
                  >
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                  <li>
                    <router-link
                      :to="{
                        name: 'Dashboard',
                      }"
                      >Dashboard</router-link
                    >
                  </li>
                  <!-- <li><a class="" href="">Settings</a></li> -->
                  <li>
                    <a @click="logOut" class="" href="javascript:void(0)"
                      >Log Out
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <!-- <div class="user-menus">
              <button
                data-toggle="dropdown"
                type="button"
                class="user_img_btn"
                style="position: relative"
              >
                <span class="hi_text">Hi, Rabin</span>
              </button>
              <div class="dropdown-menu">
                <a class="" href=""> Dashboard</a>
                <a class="" href=""> Settings</a>
                <a class="" href=""> Saved Hotels</a>
                <a class="" href=""> Log Out </a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-2 col-md-3 col-5">
          <router-link
            :to="'/' + userLogin.getUserUrlName"
            class="navbar-brand"
            v-if="userLogin.getUserUrlName != null"
            ><img src="assets/images/christmas/christmas-logo.png"
          /></router-link>
          <router-link v-else to="/" class="navbar-brand"
            ><img src="assets/images/christmas/christmas-logo.png"
          /></router-link>
        </div>
        <div class="col usp-nav">
          <nav>
            <ul>
              <li v-if="userLogin.getUserUrlName != null">
                <router-link
                  :to="'/' + userLogin.getUserUrlName + '/' + 'product'"
                  >{{ props.translationText.text.products }}</router-link
                >
              </li>
              <li v-else>
                <router-link to="/product">{{
                  props.translationText.text.products
                }}</router-link>
              </li>
              <template v-if="userLogin.getUserUrlName != null">
                <template v-for="(headerData, index) in headerNavigation">
                  <li>
                    <router-link
                      :to="{
                        name: 'CmsPageUser',
                        params: {
                          sluguser: userLogin.getUserUrlName,
                          slug: headerData.slug,
                        },
                      }"
                      >{{ headerData.name }}</router-link
                    >
                  </li>
                </template>
              </template>
              <template v-else>
                <template v-for="(headerData, index) in headerNavigation">
                  <li>
                    <router-link
                      :to="{
                        name: 'CmsPage',
                        params: { slug: headerData.slug },
                      }"
                      >{{ headerData.name }}</router-link
                    >
                  </li>
                </template>
              </template>

              <!-- <li><router-link to="/about">ABOUT US</router-link></li> -->

              <!-- <li class="dropdown">
                                <a href="javascript: ;">PRODUCTS <i class="ri-arrow-down-s-line"></i></a>
                                <ul class="dropdown-menu">
                                    <li class=""><a href="">PRODUCTS 1</a></li>
                                    <li class=""><a href="">PRODUCTS 2</a></li>                                    
                                </ul>
                            </li> -->
              <!-- <li class="">
                                <router-link :to="{ name: 'ReferenceMaterials' }">REFERENCE MATERIALS </router-link>
                            </li> -->
              <!-- <li class="">
                                <a href="javascript: ;">FAQ</a>
                            </li> -->
              <!-- <li class="">
                <a href="javascript: ;">Blog</a>
              </li> -->
              <!-- <li class="">
                                <a href="javascript: ;">SUCCESS STORIES </a>
                            </li> -->
            </ul>
          </nav>
          <div class="menu-icon-area hidden-lg hidden-md">
            <a href="javascript: ;" class="hummenu" id="hummenu"
              ><i class="ri-menu-2-fill"></i
            ></a>
          </div>
        </div>       
        <div class="col-xl-3 col-md-3">
          <a href="https://cymcorp.us/GSH-Complex-Flu-Kit-2/" target="_blank" class="save41"><img src="assets/images/christmas/christmas_offer_logo.png" alt=""></a>
        </div>
        <div class="col-xl-2 col-md-4 right-cart-language">
          <span class="cart-icon">
            <a href="javascript:void(0)" @click="opeCartPage"
              ><i class="ri-shopping-cart-2-line"></i
              ><b>{{ cartData.getCartCountQuantity }}</b></a
            >
          </span>

          <div class="dropdown flag">
            <button
              class="dropdown-toggle"
              v-if="userLanguages.getLanguageCode != null"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{
                userLanguages.getLanguageCode.charAt(0).toUpperCase() +
                userLanguages.getLanguageCode.slice(1).toLowerCase()
              }}
            </button>
            <button
              class="dropdown-toggle"
              v-else
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ defaultLanguage }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <template v-for="(language, index) in siteLanguage">
                <li>
                  <a
                    @click="userSiteLanguage(language)"
                    class="dropdown-item"
                    href="#"
                    >{{ language.name }}</a
                  >
                </li>
              </template>
            </ul>
          </div>
          <router-link
            class="btn btn-dark login"
            v-if="userLogin.checkIsLoggedIn === false"
            :to="{
              name: 'Signin',
              params: { sluguser: userLogin.getUserUrlName },
            }"
            >{{ props.translationText.text.login }}</router-link
          >
          <!-- <a href="javascript: ;" class="btn btn-dark login"> Login </a> -->
        </div>
      </div>
    </div>
  </header>
  <div class="slidemenu-overlay"></div>

  <div class="slidemenu" id="slidemenu">
    <div class="slidemenu-header">
      <a href="javascript: ;" class="close-menu" id="closemenu"
        ><i class="ri-close-circle-line"></i
      ></a>
    </div>

    <div class="slidemenu-menu-area">
      <ul class="list-unstyled slidemenu-items">
        <li v-if="userLogin.getUserUrlName != null">
          <router-link :to="'/' + userLogin.getUserUrlName + '/' + 'product'">{{
            props.translationText.text.products
          }}</router-link>
        </li>
        <li v-else>
          <router-link to="/product">{{
            props.translationText.text.products
          }}</router-link>
        </li>
        <template v-if="userLogin.getUserUrlName != null">
          <template v-for="(headerData, index) in headerNavigation">
            <li>
              <router-link
                :to="{
                  name: 'CmsPageUser',
                  params: {
                    sluguser: userLogin.getUserUrlName,
                    slug: headerData.slug,
                  },
                }"
                >{{ headerData.name }}</router-link
              >
            </li>
          </template>
        </template>
        <template v-else>
          <template v-for="(headerData, index) in headerNavigation">
            <li>
              <router-link
                :to="{
                  name: 'CmsPage',
                  params: { slug: headerData.slug },
                }"
                >{{ headerData.name }}</router-link
              >
            </li>
          </template>
        </template>
      </ul>

      <div class="login-signup">
        <router-link
          :to="{
            name: 'JoinTeam',
            params: { sluguser: userLogin.getUserUrlName },
          }"
          class="btn btn-outline-primary"
          >{{ props.translationText.text.joinTeam }}</router-link
        >
        <router-link
          v-if="userLogin.getReferalId != null"
          :to="{
            name: 'Signin',
            params: { sluguser: userLogin.getUserUrlName },
          }"
          class="btn btn-outline-primary"
          >{{ props.translationText.text.login }}</router-link
        >
        <router-link
          v-else
          :to="{ name: 'Signin' }"
          class="btn btn-outline-primary"
          >{{ props.translationText.text.login }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted } from "vue";
import { useLoginStore } from "../stores/login";
import { useCartStore } from "../stores/cart";
import { userLanguage } from "../stores/language";
import { onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import $ from "jquery";
import { useRouter } from "vue-router";

const router = useRouter();
const userLogin = useLoginStore();
const cartData = useCartStore();
const userLanguages = userLanguage();
const token = ref("");
const props = defineProps(["baseURL", "translationText"]);
const siteLanguage = ref([]);
const defaultLanguage = ref("");
const headerNavigation = ref([]);
const opeCartPage = () => {
  window.$("#cartModal").modal("show");
};

const userSiteLanguage = (language) => {
  if (userLanguages.getLanguageCode == language.code) {
    console.log("click language", language);
  } else {
    userLanguages.setLanguage(language);
    defaultLanguage.value = language.code;
    getHeader();

    // location.reload();
  }
};
const getLanguage = async () => {
  await axios
    .get(`${props.baseURL}languages`)
    //.get(apiUrl)
    .then((res) => {
      console.log("response ", res.data);
      siteLanguage.value = res.data.languages;

      if (userLanguages.getLanguageCode == null) {
        if (res.data.languages.length > 0) {
          res.data.languages.forEach((language) => {
            if (language.is_default == 1) {
              userLanguages.setLanguage(language);
              console.log("language", language);
              defaultLanguage.value = language.code;
            }
          });
        }
      }
    })
    .catch((err) => {
      //   swal({
      //     text: "Unable to Log you in!",
      //     icon: "error",
      //     closeOnClickOutside: false,
      //   });
      console.log(err);
    })
    .finally(() => {
      //loading = false;
    });
};

const getHeader = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl = props.baseURL + "navigations/header" + languageUrl;
  await axios
    .get(apiUrl)
    //.get(apiUrl)
    .then((res) => {
      console.log("response ", res.data);
      headerNavigation.value = res.data.navigations;
    })
    .catch((err) => {
      //   swal({
      //     text: "Unable to Log you in!",
      //     icon: "error",
      //     closeOnClickOutside: false,
      //   });
      console.log(err);
    })
    .finally(() => {
      //loading = false;
    });
};


onMounted(() => {
  getLanguage();
  getHeader();

  $("#slidemenu").on("show.bs.collapse", ".collapse", function () {
    $("#slidemenu").find(".sub-menu.show").collapse("hide");
  });

  // $("body .slidemenu-items li").on('click', function(){
  //   console.log("anchor clicked ");
  //   $("#slidemenu").removeClass("menu-open");
  //   $(".slidemenu-overlay").removeClass("open");
  // });

  $("#hummenu").click(function () {
    $("#slidemenu").addClass("menu-open");
    $(".slidemenu-overlay").addClass("open");
  });

  $("#closemenu").click(function () {
    $("#slidemenu").removeClass("menu-open");
    $(".slidemenu-overlay").removeClass("open");
  });
  $(".slidemenu-overlay").click(function () {
    $("#slidemenu").removeClass("menu-open");
    $(this).removeClass("open");
  });
});
const logOut = () => {
  //console.log("Logout");
  userLogin.resetUserLoggedIn();
  router.push({ name: "Home" });
};
onUpdated(() => {
  console.log("Navigation menu");
  $("#slidemenu").removeClass("menu-open");
  $(this).removeClass("open");
});
</script>

<style scoped>
#logo {
  width: 150px;
  margin-left: 20px;
  margin-right: 20px;
}

.nav-link {
  color: rgba(255, 255, 255);
}

#search-button-navbar {
  background-color: #febd69;
  border-color: #febd69;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
#nav-cart-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  font-size: 15px;
  margin-left: 10px;
}
#cart {
  position: relative;
}
</style>
